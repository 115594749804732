import styles from './Logo.css'

import visual from '/images/home/hero-typography-desktop.raw.svg'

export function Logo({ layoutClassName }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <WorkWithSoul layoutClassName={styles.workWithSoulLayout} />
    </div>
  )
}

function WorkWithSoul({ layoutClassName }) {
  return (
    <div className={cx(styles.componentWorkWithSoul, layoutClassName)}>
      <p className={styles.workWithText}>Work with</p>
      <div className={styles.visual}>
        <span dangerouslySetInnerHTML={{ __html: visual }} />
      </div>
    </div>
  )
}
